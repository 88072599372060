import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-small.png'
import corner from '../img/bottom-corner.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  
  render() {
    return (
      <footer
      style = {{
        padding: '5em 20px'
      }}
      className="footer"
      >
        <div className="content has-text-centered">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <section
                className="menu"
                >
                  <img
                    src={logo}
                    alt="Agribiz"
                    style={{ maxWidth: '250px', height: 'auto' }}
                  />
                </section>
              </div>
              <div className="column is-4">
                <section style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}>
                  <p>
                    Agribiz Ltd<br />
                    P.O.Box 11770<br />
                    Kingdom of Bahrain
                  </p>
                </section>
              </div>
              <div className="column is-4">
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}>
                  <address>
                    Mr Indi Noppe<br />
                    Phone: +44 593 799 566<br />
                    Email: <a href="mailto:info@agribizltd.com">info@agribizltd.com</a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
